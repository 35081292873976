<template>
  <div style="height: 100vh">
    <div class="columns is-centered is-vcentered">
      <div class="column is-half" style="height: 100%">
        <div class="card">
          <div class="card-header has-background-dark">
            <div class="card-header-title has-text-white is-centered">
              <!--<span>{{ siteName }} Papara Transfer</span>-->
              <img width="300" :src="`./../../assets/${site}/logos/papara.svg`" />
            </div>
          </div>
          <div class="card-content is-centered" v-if="status">
            <p class="mb-4 has-text-centered">Sayın {{ form.name }}, lütfen yatırmak istediğiniz rakamı girin ve işleme
              devam edin.</p>
            <hr/>
            <hr/>
            <p class="mb-3 p-3">
              <b-field label="Yatırmak istediğiniz tutar">
                <b-input type="number" v-model="form.amount" placeholder="Yatırmak istediğiniz tutar" min="90"
                         max="30000"/>
              </b-field>
            </p>
            <hr/>
            <p class="mb-3 p-3">
              <b-button @click="pay" size="is-small" type="is-success" :loading="loading"
                        :disabled="loading || form.amount < 90 || form.amount > 30000">Devam Et
              </b-button>
            </p>
            <hr/>
            <div class="is-flex is-justify-content-center">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {LuckyService} from '@/services'

export default {
  name: 'PaparaPayManual',
  data() {
    return {
      loading: false,
      form: {
        amount: 90,
        name: null,
        userId: null,
        appkey: this.$route.params.id,
        identity: null
      },
      status: false
    }
  },
  mounted() {
    const html = document.getElementsByTagName('html')[0]
    html.classList = []
    this.init()
  },
  methods: {
    pay() {
      LuckyService.paparaDeposit({...this.form}).then(res => {
        window.location.replace(res.data.depositUrl);
      }).catch(err => {
        this.status = false
        this.$buefy.toast.open({message: err.message, position: 'is-top-right', type: 'is-danger'})
      })
    },
    init() {
      if (!this.$route.query.sid || !this.$route.params.id) {
        this.$buefy.toast.open({message: 'Hatalı işlem!', position: 'is-top-right', type: 'is-danger'})
        return
      }
      this.status = true
      LuckyService.getCustomer({sid: this.$route.query.sid})
          .then(res => {
            if (res.data.status) {
              this.form.name = res.data.data.F030 + ' ' + res.data.data.F040
              this.form.userId = res.data.data.F001
              this.form.description = res.data.data.F010
              this.form.username = res.data.data.F020
              this.form.identity = res.data.data.F050
            } else {
              this.status = false
              this.$buefy.toast.open({message: 'Hatalı işlem', position: 'is-top-right', type: 'is-danger'})
            }
          })
          .catch(err => {
            this.status = false
            this.$buefy.toast.open({message: err.message, position: 'is-top-right', type: 'is-danger'})
          })
    }
  }
}
</script>

<style>
.qrcode > canvas {
  border: 6px solid #ddd;
  padding: .5rem;
  border-radius: 12px;
}

.input[type="text"]::placeholder {
  color: #000000 !important
}

.input[type="text"] {
  color: #000000 !important
}
</style>
